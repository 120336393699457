import type { PropsWithChildren, ReactNode } from 'react';

import { ApplicationIcon } from '~/components/application-icons';
import { Card, CardContent, CardHeader, CardIcon, CardTitle } from '~/components/ui/card';
import { Separator } from '~/components/ui/separator';

export interface SummaryCardProps {
  title: ReactNode;
  className?: string;
}

export const SummaryCard = ({ className, title, children }: Readonly<PropsWithChildren<SummaryCardProps>>) => (
  <Card className={className}>
    <CardHeader>
      <CardIcon>
        <ApplicationIcon icon='currency' />
      </CardIcon>
      <CardTitle className='gap-4 text-center'>{title}</CardTitle>
    </CardHeader>

    <CardContent>
      <Separator />
      {children}
    </CardContent>
  </Card>
);
